import Cookies from 'js-cookie';

import { Deployment } from './deployment';
import { logoutUser } from './navigation-utils';

const { host, reportsURL } = Deployment;

async function GetVersion(cb) {
  await fetch(`${host}/version`, {
    method: 'get',
  })
    .then(async response => {
      const resp = await response.json();

      cb(true, resp.data);
    })
    .catch(error => {
      console.error(error);
      cb(false);
    });
}

function needsVersionUpdate(headers) {
  if (!process.env.VERSION_UPDATE_ENABLED) return false;

  const version = headers.get('x-build-version');

  if (version && version !== process.env.VERSION) {
    return true;
  }

  return false;
}

async function Get(path, data = null, opts, headers) {
  return requestHelper(path, data, 'get', opts, headers);
}

async function Post(path, data, opts, headers) {
  return requestHelper(path, data, 'post', opts, headers);
}

async function Put(path, data, opts, headers) {
  return requestHelper(path, data, 'put', opts, headers);
}

async function Delete(path, data, opts, headers) {
  return requestHelper(path, data, 'delete', opts, headers);
}

async function requestHelper(path, data = null, method, opts = {}, headers = {}) {
  let requestBody;
  if (data) {
    requestBody = JSON.stringify(data);
    if (opts.rawBody) {
      requestBody = data;
    }
  }

  const needsReportUrl = path.includes('reports/') || path.includes('client/accounts');
  const url = needsReportUrl ? reportsURL : host;

  const token = Cookies.get('x-auth-token');

  const response = await fetch(`${url}/${path}`, {
    method,
    body: requestBody,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'x-client-build': process.env.WEB_ENV !== 'development' ? `web:${process.env.VERSION}` : '',
      ...headers,
    }),
    signal: opts.signal,
  }).catch(error => {
    if (opts?.signal?.aborted) return Promise.resolve();
    return Promise.reject(error);
  });

  if (opts?.signal?.aborted) return Promise.resolve();

  const updateAvailable = needsVersionUpdate(response.headers);

  if (updateAvailable && process.env.WEB_ENV !== 'alpha') {
    /*
      Mechanism to automatically force a page reload, ensuring that the 
      client stays in sync with the API server, if there were any API
      changes that could cause issues and to get the latest features
    */
    window.location.reload();
    return undefined;
  }

  const persistedResponseHeaders = {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    updateAvailable,
  };

  switch (response.status) {
    case 200:
      const contentLength = response.headers.get('Content-Length');
      if (contentLength && contentLength === '0') {
        return persistedResponseHeaders;
      }
      return { ...(await response.json()), ...persistedResponseHeaders };
    case 401:
      // If they got here then they got bad headers or are no longer allowed to access
      // the app. Just log them out.
      const keepInAppPaths = ['order/approval'];

      if (!keepInAppPaths.includes(path)) {
        logoutUser(true);
      }

      return { error: response.error, ...persistedResponseHeaders };
    default:
      /*
        This will be a 204, 400, 401, or some other bad request.
      */
      return { ...(await response?.json()), ...persistedResponseHeaders };
  }
}

async function PostNoAuth(path, data) {
  const url = `${host}/${path}`;

  const req = await fetch(url, {
    method: 'post',
    body: JSON.stringify(data),
  });

  return req.json();
}

async function RecordAction(action) {
  const response = await requestHelper(
    'action',
    {
      action,
    },
    'post'
  );

  if (response.error) {
    console.error(response.error);
  }
}

async function UploadImage(file, identifier, imageType) {
  const blob = new Blob([file], { type: 'text/plain' });

  const formData = new FormData();
  formData.append('file', blob);

  const ext = file.name.split('.').pop();
  formData.append('ext', ext);

  switch (imageType) {
    case 'product':
      formData.append('productID', identifier);
      break;
    case 'sku':
      formData.append('orderItemID', identifier);
      break;
    default:
      break;
  }

  return Post('upload', formData, { rawBody: true });
}

async function UploadFile(file, identifier, referenceType, type = 'attachment', isPrivate = false) {
  const blob = new Blob([file], { type: 'text/plain' });

  const formData = new FormData();
  formData.append('file', blob);

  const ext = file.name.split('.').pop();
  formData.append('ext', ext);

  formData.append('name', file.name);
  formData.append('type', type);

  switch (referenceType) {
    case 'product':
      formData.append('productID', identifier);
      break;
    case 'sku':
      formData.append('orderItemID', identifier);
      break;
    case 'order':
      formData.append('orderID', identifier);
      break;
    case 'appraisal':
      formData.append('appraisalID', identifier);
      break;
    case 'customer':
      formData.append('customerID', identifier);
      break;
    case 'organization':
      formData.append('organizationID', identifier);
      break;
    case 'merchReceive':
      formData.append('merchID', identifier);
      break;
    case 'purchaseOrder':
      formData.append('purchaseOrderID', identifier);
      break;
    case 'tradeIn':
      formData.append('tradeInID', identifier);
      break;
    default:
      break;
  }

  const path = isPrivate ? 'upload/private' : 'upload';

  return Post(path, formData, { rawBody: true });
}

async function SendReport(wbout, ext, emails = [], meta = {}) {
  const blob = new Blob([wbout], { type: 'text/plain' });

  const recipients = emails.map(email => email.value);

  const formData = new FormData();
  formData.append('file', blob);
  formData.append('ext', ext);
  formData.append('recipients', recipients.join(','));
  formData.append('meta', JSON.stringify(meta));

  return Post('report/send', formData, { rawBody: true });
}

async function DownloadAttachment(id, cb) {
  const token = Cookies.get('x-auth-token');

  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  };

  const url = `${host}/download/attachment?id=${id}`;

  return fetch(url, requestOptions)
    .then(cb)
    .catch(() => {
      console.error('Failed to download: ', url);
    });
}

const BasicAuth = user => {
  const { username, password } = user;

  return `Basic ${btoa(`${username}:${password}`)}`;
};

export {
  Get,
  GetVersion,
  Post,
  Put,
  Delete,
  RecordAction,
  PostNoAuth,
  UploadImage,
  UploadFile,
  DownloadAttachment,
  SendReport,
  BasicAuth,
};
