export enum AccountsReceivableType {
  Collection = 'collection',
  Payment = 'payment',
  StoreCredit = 'storecredit',
  Interest = 'interest',
  PreviousBalance = 'previousbalance', // Only defined in web, do not use for saving data
  Unknown = 'unknown', // Only defined in web, do not use for saving data
}

export interface AccountsReceivableItem {
  id: number;
  amount: number;
  amount_paid: number;
  amount_string: string;
  balance: number;
  customer_uuid: string;
  order_uuid: string;
  payment_date: string;
  payment_date_string: string;
  payment_uuid: string;
  ref_id: string;
  transaction_number: string;
  date: string;
  type?: string;
}
