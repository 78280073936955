import { formatMoney } from 'utils/money';
import { translate } from 'utils/translation';
import { ReportRow } from './field_types';

export const VENDOR_PERFORMANCE_REPORT_FIELDS = [
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    isDefault: true,
    sortable: false,
    filterable: true,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity || 0,
  },
  {
    name: 'Amount',
    id: 'subtotal',
    key: 'subtotal',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.subtotal),
  },

  {
    name: 'Discount',
    id: 'applied_discounts',
    key: 'applied_discounts',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.applied_discounts),
  },
  {
    name: 'Ext. Price',
    id: 'subtotal_discounts_applied',
    key: 'subtotal_discounts_applied',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.subtotal_discounts_applied),
  },
  {
    name: 'Tax',
    id: 'tax',
    key: 'tax',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.tax),
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.cost),
  },
  {
    name: 'Gross Profit ($)',
    id: 'profit',
    key: 'profit',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.profit),
  },
  {
    id: 'margin',
    key: 'margin',
    name: 'Gross Profit (%)',
    type: 'PERCENT',
    part: 'profit',
    whole: 'subtotal_discounts_applied',
    isDefault: true,
    sortable: true,
    render: row => `${(Number(row.margin) * 100).toFixed(2)}%`,
  },
  {
    name: translate('reports', 'vendor-performance.total-ex-vat', 'Ex-VAT'),
    id: 'ex_vat',
    key: 'ex_vat',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ex_vat),
  },
];
