import { _apiRequest } from 'redux/actions/system';
import {
  ADD_INVOICE,
  EDIT_INVOICE,
  EDIT_INVOICE_ITEM,
  GET_INVOICE,
  SET_MERCH_PRODUCT_ID,
  SET_SEARCH_PRODUCTS,
} from 'redux/constants/action-types';
import history from 'utils/history';
import * as Request from 'utils/request';

export const addInvoice = (payload, cb) => async dispatch =>
  _apiRequest({
    actionBase: ADD_INVOICE,
    dispatch,
    method: 'Post',
    path: `invoice`,
    payload,
    callback: uuid => {
      cb && cb();

      if (uuid) {
        const navBasePath = payload.return ? 'rtv' : 'receives';
        history.replace(`/${navBasePath}/${uuid}`);
      }
    },
  });

export const editInvoice = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_INVOICE,
    dispatch,
    method: 'Put',
    path: `invoice`,
    payload,
    returnPayloadOnSuccess: true,
    handleStatus: status => {
      switch (status) {
        case 409:
          window.showNotification(
            'error',
            'Not Allowed',
            'This record contains one or more Stock #s that have additional movement history. For details, please contact your system administrator.'
          );
          break;
        default:
          break;
      }
    },
    onSuccess: () => {
      window.showSuccess('Saved');

      dispatch(getInvoice(payload.uuid));
    },
  });

export const editInvoiceItem = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_INVOICE_ITEM,
    dispatch,
    method: 'Put',
    path: `invoice/item`,
    payload,
    onSuccess: () => {
      window.showSuccess('Saved');

      dispatch(getInvoice(payload.uuid));
    },
  });

export const getInvoice = (id, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: GET_INVOICE,
    dispatch,
    method: 'Get',
    path: `invoice?id=${id}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const addItemToInvoiceByProduct = payload => async dispatch => {
  const response = await Request.Post('invoice/sku', {
    id: payload.invoiceId,
    itemID: payload.skuId,
    quantity: Number(payload.quantity),
    return: payload.isReturn,
    productID: payload.productId,
    cost: Number(payload.cost),
    purchase_price: Number(payload.purchase_price),
    exchange_rate: Number(payload.exchange_rate),
    taxable: payload.taxable,
    memo: payload.memo,
    serial: payload.serial,
    warranty: payload.warranty,
    serialize: payload.serialize,
    add: false,
    purchase_date: payload.purchase_date,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 409) {
    window.showError('Incorrect vendor');
    return;
  }

  if (!payload.ignoreFetch) {
    dispatch(getInvoice(payload.uuid));
  }
};

export const addItemToInvoiceBySku = payload => async dispatch => {
  const data = {
    id: payload.invoiceId,
    itemID: payload.skuId,
    quantity: Number(payload.quantity),
    return: payload.isReturn,
    productID: payload.productId,
    cost: parseFloat(payload.cost),
    taxable: payload.taxable,
    memo: payload.memo,
    add: true,
    receive_date: payload.receive_date,
  };

  const response = await Request.Post('invoice/sku', data);

  if (response.status === 409) {
    window.showError('Incorrect vendor');
    return;
  }

  if (response.status === 417) {
    window.showNotification(
      'error',
      'Quantity Not Available',
      'The quantity you requested for this Stock # is not available at this location.'
    );

    return;
  }

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (!payload.ignoreFetch) {
    dispatch(getInvoice(payload.uuid));
  }
};

export const setMerchProductId = payload => ({
  type: SET_MERCH_PRODUCT_ID,
  payload,
});

export const setMerchProductToSearchResult = payload => ({
  type: SET_SEARCH_PRODUCTS,
  payload,
});

export const returnRTVItemToStock = payload => async dispatch => {
  const { uuid, id, isReturn, sku, merch_id } = payload;
  const response = await Request.Put('invoice/item', {
    id,
    sku,
    merch_id,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  let msg = '';

  if (isReturn) {
    if (!sku) {
      msg = 'The Stock # has been returned to stock.';
    } else {
      msg = 'The SKU has been returned to stock.';
    }
  } else {
    if (response.status !== 200) {
      window.showNotification(
        'error',
        'Not Allowed',
        'You are not allowed to make this change. Please contact your system administrator.'
      );
      return;
    }

    msg = 'The Stock # has been removed from the system.';
  }

  window.showSuccess(msg);

  dispatch(getInvoice(uuid));
};
