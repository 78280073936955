import { failReqActionType, successReqActionType } from '../actions/helpers';
import {
  AUTH_CARD,
  AUTH_FALLBACK,
  COMPLETED_TRANSACTION_SESSION,
  GET_CC_TRANSACTION,
  GET_TERMINALS,
  INITIATED_TRANSACTION_SESSION,
  REFUND_CC_TRANSACTION,
  RESET_ALL_DATA,
  RESET_CARD,
  RESET_ORDER,
  RESET_TRANSACTION_SESSION,
  SET_CARD_PROCESSING,
} from '../constants/action-types';

const initialState = {
  terminals: [],
  card: null,
  transaction_session: null,
  processing: false,
  transaction: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_TERMINALS):
      return {
        ...state,
        terminals: action.payload,
      };
    case failReqActionType(INITIATED_TRANSACTION_SESSION):
    case failReqActionType(COMPLETED_TRANSACTION_SESSION):
      return {
        ...state,
        transaction_session: {
          ...action.payload,
        },
        processing: false,
      };
    case failReqActionType(AUTH_CARD):
      return {
        ...state,
        card: {
          ...action.payload,
          errorCode: action.payload.errorCode || 10,
          errorMessage:
            action.payload.errorMessage || action.payload.message || action.payload.resptext || 'An error occurred',
        },
        processing: false,
      };
    case successReqActionType(AUTH_CARD):
      return {
        ...state,
        card: {
          ...action.payload,
          orderId: action.orderId,
        },
        processing: false,
      };
    case successReqActionType(AUTH_FALLBACK):
      return {
        ...state,
        card: {
          ...action.payload,
          fallback: true,
        },
        processing: false,
      };
    case SET_CARD_PROCESSING:
      return {
        ...state,
        processing: action.payload,
        card: null,
      };
    case successReqActionType(GET_CC_TRANSACTION):
      return {
        ...state,
        transaction: {
          ...action.payload,
          hsn: action.hsn,
        },
      };
    case failReqActionType(REFUND_CC_TRANSACTION):
    case successReqActionType(REFUND_CC_TRANSACTION):
      return {
        ...state,
        transaction: {
          ...action.payload,
          [action.payload?.type]: true,
        },
      };
    case successReqActionType(INITIATED_TRANSACTION_SESSION):
      return {
        ...state,
        transaction_session: {
          session_id: action.payload,
        },
        processing: true,
      };
    case successReqActionType(COMPLETED_TRANSACTION_SESSION):
    case RESET_TRANSACTION_SESSION:
      return {
        ...state,
        transaction_session: null,
        processing: false,
      };
    case RESET_CARD:
      return {
        ...state,
        card: null,
        transaction: null,
      };
    case RESET_ORDER:
      return {
        ...state,
        card: null,
        processing: false,
        transaction: null,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
