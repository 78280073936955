// Root / System actions
export const SET_USER = 'SET_USER';
export const TOGGLE_TIME_CLOCK = 'TOGGLE_TIME_CLOCK';
export const GET_TIME_CLOCK = 'GET_TIME_CLOCK';
export const API_SERVER_CONNECTED = 'API_SERVER_CONNECTED';
export const AMQP_SERVER_CONNECTED = 'AMQP_SERVER_CONNECTED';
export const NEEDS_UPDATE = 'NEEDS_UPDATE';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const SET_TABLE_INFO = 'SET_TABLE_INFO';
export const RESET_TABLE_PAGE = 'RESET_TABLE_PAGE';
export const SET_DASHBOARD_FILTERS = 'SET_DASHBOARD_FILTERS';
export const SET_DASHBOARD_TAB = 'SET_DASHBOARD_TAB';
export const RESET_ALL_DATA = 'RESET_ALL_DATA';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const GET_CHANGE_LOG = 'GET_CHANGE_LOG';
export const RESET_CHANGE_LOG = 'RESET_CHANGE_LOG';
export const GET_VIEW_HISTORY_LOG = 'GET_VIEW_HISTORY_LOG';
export const RESET_VIEW_HISTORY_LOG = 'RESET_VIEW_HISTORY_LOG';
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_END = 'REQUEST_END';
export const REQUEST_ABORT = 'REQUEST_ABORT';

// Roles / Permissions
export const GET_ROLES = 'GET_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLE_PERMISSION = 'EDIT_ROLE_PERMISSION';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const EDIT_PERMISSION = 'EDIT_PERMISSION';
export const GET_LAYOUTS = 'GET_LAYOUTS';
export const SELECT_LAYOUT = 'SELECT_LAYOUT';

// Admin
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATION = 'GET_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const ADD_LOCATION = 'ADD_LOCATION';

export const GET_TAX_CODES = 'GET_TAX_CODES';
export const GET_TAX_CODE = 'GET_TAX_CODE';
export const EDIT_TAX_CODE = 'EDIT_TAX_CODE';
export const ADD_TAX_CODE = 'ADD_TAX_CODE';

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_ALL = 'GET_PAYMENT_METHODS_ALL';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const SET_CARD_DATA = 'SET_CARD_DATA';

export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDOR = 'GET_VENDOR';
export const EDIT_VENDOR = 'EDIT_VENDOR';
export const ADD_VENDOR = 'ADD_VENDOR';
export const GET_VENDOR_TRANSACTIONS = 'GET_VENDOR_TRANSACTIONS';
export const GET_VENDOR_MERCH_HISTORY = 'GET_VENDOR_MERCH_HISTORY';
export const GET_VENDOR_PURCHASE_HISTORY = 'GET_VENDOR_PURCHASE_HISTORY';
export const GET_VENDOR_MEMO_ITEMS = 'GET_VENDOR_MEMO_ITEMS';
export const GET_VENDOR_PRODUCTS = 'GET_VENDOR_PRODUCTS';

export const ADD_VENDOR_EMAIL = 'ADD_VENDOR_EMAIL';
export const EDIT_VENDOR_EMAIL = 'EDIT_VENDOR_EMAIL';
export const ADD_VENDOR_PHONE = 'ADD_VENDOR_PHONE';
export const EDIT_VENDOR_PHONE = 'EDIT_VENDOR_PHONE';
export const ADD_VENDOR_ADDRESS = 'ADD_VENDOR_ADDRESS';
export const EDIT_VENDOR_ADDRESS = 'EDIT_VENDOR_ADDRESS';
export const EDIT_VENDOR_PRIMARY_FIELD = 'EDIT_VENDOR_PRIMARY_FIELD';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const CREATE_PRODUCT_TYPE = 'CREATE_PRODUCT_TYPE';
export const UPDATE_PRODUCT_TYPE = 'UPDATE_PRODUCT_TYPE';

export const GET_USERS = 'GET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const ADD_USER = 'ADD_USER';

export const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
export const ADD_PRODUCT_ATTRIBUTE = 'ADD_PRODUCT_ATTRIBUTE';
export const ADD_PRODUCT_ATTRIBUTE_VALUE = 'ADD_PRODUCT_ATTRIBUTE_VALUE';
export const REMOVE_PRODUCT_ATTRIBUTE_VALUE = 'REMOVE_PRODUCT_ATTRIBUTE_VALUE';
export const DEACTIVATE_PRODUCT_ATTRIBUTE = 'DEACTIVATE_PRODUCT_ATTRIBUTE';
export const UPDATE_PRODUCT_ATTRIBUTE_VALUES = 'UPDATE_PRODUCT_ATTRIBUTE_VALUES';
export const UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER = 'UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER';
export const UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS = 'UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS';

export const GET_SKU_CATEGORIES = 'GET_SKU_CATEGORIES';
export const ADD_SKU_CATEGORY = 'ADD_SKU_CATEGORY';
export const EDIT_SKU_CATEGORY = 'EDIT_SKU_CATEGORY';

export const GET_ORDER_CODES = 'GET_ORDER_CODES';
export const GET_ORDER_CODE = 'GET_ORDER_CODE';
export const EDIT_ORDER_CODE = 'EDIT_ORDER_CODE';
export const ADD_ORDER_CODE = 'ADD_ORDER_CODE';

export const GET_MARKETING_SOURCES = 'GET_MARKETING_SOURCES';
export const EDIT_MARKETING_SOURCE = 'EDIT_MARKETING_SOURCE';
export const ADD_MARKETING_SOURCE = 'ADD_MARKETING_SOURCE';

export const GET_ORDER_TYPES = 'GET_ORDER_TYPES';
export const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
export const CREATE_ORDER_TYPE = 'CREATE_ORDER_TYPE';

export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const UPDATE_PAYMENT_TERM = 'UPDATE_PAYMENT_TERM';
export const CREATE_PAYMENT_TERM = 'CREATE_PAYMENT_TERM';

export const GET_JOB_TYPES = 'GET_JOB_TYPES';
export const UPDATE_JOB_TYPE = 'UPDATE_JOB_TYPE';
export const CREATE_JOB_TYPE = 'CREATE_JOB_TYPE';

export const GET_PRICE_CHANGES = 'GET_PRICE_CHANGES';

// SKUs
export const ADD_SKU = 'ADD_SKU';
export const EDIT_SKU = 'EDIT_SKU';
export const GET_SKU = 'GET_SKU';
export const GET_GENERAL_SKUS = 'GET_GENERAL_SKUS';
export const GET_SKU_TRANSACTIONS = 'GET_SKU_TRANSACTIONS';
export const GET_SKU_SCANS = 'GET_SKU_SCANS';
export const GET_SKU_COMPONENTS = 'GET_SKU_COMPONENTS';
export const UPDATE_SKU_SCAN = 'UPDATE_SKU_SCAN';
export const GET_SKU_STOCK = 'GET_SKU_STOCK';
export const GET_SKU_SALES = 'GET_SKU_SALES';
export const GET_DIAMOND_ATTRIBUTES = 'GET_DIAMOND_ATTRIBUTES';
export const EDIT_DIAMOND_ATTRIBUTES = 'EDIT_DIAMOND_ATTRIBUTES';
export const ADD_SKU_COMPONENT = 'ADD_SKU_COMPONENT';
export const UPDATE_SKU_COMPONENT = 'UPDATE_SKU_COMPONENT';
export const ADD_SKU_ADJUSTMENT = 'ADD_SKU_ADJUSTMENT';
export const UPDATE_INVOICE_SKU = 'UPDATE_INVOICE_SKU';

// Products
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const MERGE_PRODUCTS = 'MERGE_PRODUCTS';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_META = 'EDIT_PRODUCT_META';
export const RESET_NEW_PRODUCT = 'RESET_NEW_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SALES = 'GET_PRODUCT_SALES';
export const GET_PRODUCT_INVENTORY = 'GET_PRODUCT_INVENTORY';
export const GET_PRODUCT_ATTACHMENTS = 'GET_PRODUCT_ATTACHMENTS';
export const DELETE_PRODUCT_ATTACHMENTS = 'DELETE_PRODUCT_ATTACHMENTS';
export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';
export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES';
export const SET_PRODUCT_ON_ORDER = 'SET_PRODUCT_ON_ORDER';
export const SET_ATTRIBUTE_MAP = 'SET_ATTRIBUTE_MAP';
export const GET_PRODUCT_TRANSACTIONS = 'GET_PRODUCT_TRANSACTIONS';
export const GET_PRODUCT_WISHLIST = 'GET_PRODUCT_WISHLIST';
export const GET_PRODUCT_MOVEMENT = 'GET_PRODUCT_MOVEMENT';
export const GET_REORDER_PRODUCTS = 'GET_REORDER_PRODUCTS';
export const SET_REORDER_VENDOR = 'SET_REORDER_VENDOR';
export const SET_STANDARD_ATTRIBUTES = 'SET_STANDARD_ATTRIBUTES';

// Transfers
export const ADD_TRANSFER = 'ADD_TRANSFER';
export const EDIT_TRANSFER = 'EDIT_TRANSFER';
export const GET_TRANSFER = 'GET_TRANSFER';
export const GET_TRANSFERS = 'GET_TRANSFERS';
export const SET_TRANSFER_ITEMS = 'SET_TRANSFER_ITEMS';
export const ADD_TRANSFER_ITEM = 'ADD_TRANSFER_ITEM';

// Merch Received / RTV
export const ADD_INVOICE = 'ADD_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const RETURN_RTV_ITEM_TO_STOCK = 'RETURN_RTV_ITEM_TO_STOCK';
export const SET_MERCH_PRODUCT_ID = 'SET_MERCH_PRODUCT_ID';
export const SET_SEARCH_PRODUCTS = 'SET_SEARCH_PRODUCTS';
export const EDIT_INVOICE_ITEM = 'EDIT_INVOICE_ITEM';

// Search
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const SET_SEARCH_KEY = 'SET_SEARCH_KEY';
export const SEARCH_VENDORS = 'SEARCH_VENDORS';
export const SEARCH_TRANSFERS = 'SEARCH_TRANSFERS';
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const SEARCH_HIDDEN_CLIENTS = 'SEARCH_HIDDEN_CLIENTS';
export const SET_CLIENT_SEARCH_FILTERS = 'SET_CLIENT_SEARCH_FILTERS';
export const SEARCH_ORDERS = 'SEARCH_ORDERS';
export const SEARCH_REPAIRS = 'SEARCH_REPAIRS';
export const SEARCH_GIFT_CARDS = 'SEARCH_GIFT_CARDS';
export const SEND_GIFT_CARD = 'SEND_GIFT_CARD';
export const SEARCH_INVOICES = 'SEARCH_INVOICES';
export const SEARCH_PURCHASE_ORDERS = 'SEARCH_PURCHASE_ORDERS';
export const SEARCH_SKUS = 'SEARCH_SKUS';
export const SET_PRODUCT_SEARCH_FILTERS = 'SET_PRODUCT_SEARCH_FILTERS';
export const SET_SKU_SEARCH_FILTERS = 'SET_SKU_SEARCH_FILTERS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';

// Clients
export const SET_CLIENT_SEARCH_TYPE = 'SET_CLIENT_SEARCH_TYPE';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENTS_WITH_ACCOUNT = 'GET_CLIENTS_WITH_ACCOUNT';
export const ADD_ON_ACCOUNT_STATEMENTS = 'ADD_ON_ACCOUNT_STATEMENTS';
export const MERGE_CLIENTS = 'MERGE_CLIENTS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const ADD_CLIENT_EMAIL = 'ADD_CLIENT_EMAIL';
export const EDIT_CLIENT_EMAIL = 'EDIT_CLIENT_EMAIL';
export const ADD_CLIENT_PHONE = 'ADD_CLIENT_PHONE';
export const EDIT_CLIENT_PHONE = 'EDIT_CLIENT_PHONE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_BULK_EMAIL = 'SEND_BULK_EMAIL';
export const SEND_TEXT_RECEIPT = 'SEND_TEXT_RECEIPT';
export const ADD_CLIENT_ADDRESS = 'ADD_CLIENT_ADDRESS';
export const EDIT_CLIENT_ADDRESS = 'EDIT_CLIENT_ADDRESS';
export const GET_CLIENT_HISTORY = 'GET_CLIENT_HISTORY';
export const GET_ITEM_HISTORY = 'GET_ITEM_HISTORY';
export const GET_STORE_CREDITS = 'GET_STORE_CREDITS';
export const ADD_STORE_CREDIT = 'ADD_STORE_CREDIT';
export const CREATE_CLIENT_REGISTRY = 'CREATE_CLIENT_REGISTRY';
export const GET_CLIENT_REGISTRY = 'GET_CLIENT_REGISTRY';
export const UPDATE_CLIENT_REGISTRY = 'UPDATE_CLIENT_REGISTRY';
export const SET_REGISTRY_ON_ORDER = 'SET_REGISTRY_ON_ORDER';
export const GET_CLIENT_RELATIONSHIPS = 'GET_CLIENT_RELATIONSHIPS';
export const ADD_CLIENT_RELATIONSHIPS = 'ADD_CLIENT_RELATIONSHIPS';
export const DEACTIVATE_CLIENT_RELATIONSHIPS = 'DEACTIVATE_CLIENT_RELATIONSHIPS';
export const GET_CLIENT_REFERRALS = 'GET_CLIENT_REFERRALS';
export const ADD_CLIENT_REFERRALS = 'ADD_CLIENT_REFERRALS';
export const DEACTIVATE_CLIENT_REFERRALS = 'DEACTIVATE_CLIENT_REFERRALS';
export const EDIT_CLIENT_METADATA = 'EDIT_CLIENT_METADATA';
export const GET_CLIENT_WISHLIST = 'GET_CLIENT_WISHLIST';
export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const UPDATE_WISHLIST_ITEM = 'UPDATE_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export const EDIT_CLIENT_NAME = 'EDIT_CLIENT_NAME';
export const EDIT_CLIENT_PRIMARY_FIELD = 'EDIT_CLIENT_PRIMARY_FIELD';
export const SET_CLIENT_PAGE_CLIENT_ID = 'SET_CLIENT_PAGE_CLIENT_ID';
export const GET_CLIENT_COLLECTIONS = 'GET_CLIENT_COLLECTIONS';
export const ADD_CLIENT_COLLECTION = 'ADD_CLIENT_COLLECTION';
export const EDIT_CLIENT_COLLECTION = 'EDIT_CLIENT_COLLECTION';
export const GET_CLIENT_PAYMENT = 'GET_CLIENT_PAYMENT';
export const GET_CLIENT_REFUND = 'GET_CLIENT_REFUND';
export const GET_CLIENT_VISITS = 'GET_CLIENT_VISITS';
export const UPDATE_CLIENT_VISITS = 'UPDATE_CLIENT_VISITS';
export const SET_CLIENT_VISITS = 'SET_CLIENT_VISITS';
export const GET_CLIENT_MILESTONES = 'GET_CLIENT_MILESTONES';
export const UPDATE_CLIENT_MILESTONES = 'UPDATE_CLIENT_MILESTONES';
export const SET_CLIENT_MILESTONES = 'SET_CLIENT_MILESTONES';
export const DELETE_CLIENT_MILESTONES = 'DELETE_CLIENT_MILESTONES';
export const GET_CREDIT_PLANS = 'GET_CREDIT_PLANS';
export const GET_CLIENT_STATEMENT_HISTORY = 'GET_CLIENT_STATEMENT_HISTORY';
export const GET_CLIENT_HISTORICAL_STATEMENT = 'GET_CLIENT_HISTORICAL_STATEMENT';
export const GET_CLIENT_ON_ACCOUNT_STATEMENTS = 'GET_CLIENT_ON_ACCOUNT_STATEMENTS';
export const GET_CLIENT_INTEREST_DUE = 'GET_CLIENT_INTEREST_DUE';
export const GET_CLIENT_REWARDS_HISTORY = 'GET_CLIENT_REWARDS_HISTORY';
export const APPLY_CLIENT_INTEREST_DUE = 'APPLY_CLIENT_INTEREST_DUE';

// Browse Inventory
export const SET_BROWSE_INVENTORY_FILTERS = 'SET_BROWSE_INVENTORY_FILTERS';
export const RESET_BROWSE_INVENTORY_FILTERS = 'RESET_BROWSE_INVENTORY_FILTERS';
export const RESET_BROWSE_INVENTORY_FILTER = 'RESET_BROWSE_INVENTORY_FILTER';
export const SET_BROWSE_INVENTORY_VIEW_MODE = 'SET_BROWSE_INVENTORY_VIEW_MODE';
export const SET_BROWSE_INVENTORY_SORT = 'SET_BROWSE_INVENTORY_SORT';
export const BROWSE_PRODUCTS = 'BROWSE_PRODUCTS';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const UPDATE_BROWSE_INVENTORY = 'UPDATE_BROWSE_INVENTORY';
export const GET_MERCH_RECEIVED_HISTORY = 'GET_MERCH_RECEIVED_HISTORY';

// Transactions
export const GET_ORDER = 'GET_ORDER';
export const GET_TAG = 'GET_TAG';
export const GET_TAGS = 'GET_TAGS';
export const GET_ORDERS_BY_TYPE = 'GET_ORDERS_BY_TYPE';
export const GET_ORDERS_BY_UUIDS = 'GET_ORDERS_BY_UUIDS';
export const GET_ORDER_APPRAISALS = 'GET_ORDER_APPRAISALS';
export const UPDATE_ORDER_ITEM_APPRAISAL = 'UPDATE_ORDER_ITEM_APPRAISAL';
export const GET_APPRAISAL_ORDERS = 'GET_APPRAISAL_ORDERS';
export const GET_APPRAISAL = 'GET_APPRAISAL';
export const UPDATE_APPRAISAL = 'UPDATE_APPRAISAL';
export const UPDATE_APPRAISAL_EXTRA_INFO = 'UPDATE_APPRAISAL_EXTRA_INFO';
export const CREATE_APPRAISAL = 'CREATE_APPRAISAL';
export const EDIT_APPRAISAL_ITEM = 'EDIT_APPRAISAL_ITEM';
export const CREATE_APPRAISAL_ITEM = 'CREATE_APPRAISAL_ITEM';
export const ADD_SKU_TO_ORDER = 'ADD_SKU_TO_ORDER';
export const EDIT_SKU_ON_ORDER = 'EDIT_SKU_ON_ORDER';
export const REMOVE_SKU_FROM_ORDER = 'REMOVE_SKU_FROM_ORDER';
export const ADD_PM_TO_ORDER = 'ADD_PM_TO_ORDER';
export const EDIT_PM_ON_ORDER = 'EDIT_PM_ON_ORDER';
export const REMOVE_PM_FROM_ORDER = 'REMOVE_PM_FROM_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_CLIENT_FORM_ON_ORDER = 'SET_CLIENT_FORM_ON_ORDER';
export const RESET_CLIENT_FORM_ON_ORDER = 'RESET_CLIENT_FORM_ON_ORDER';
export const RESET_ORDER = 'RESET_ORDER';
export const IS_EDITING_ORDER = 'IS_EDITING_ORDER';
export const IS_WHOLESALE_CLIENT = 'IS_WHOLESALE_CLIENT';
export const SELECT_CONTACT_DATA_ELEMENT = 'SELECT_CONTACT_DATA_ELEMENT';
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const ADD_ATTACHMENT_TO_ORDER = 'ADD_ATTACHMENT_TO_ORDER';
export const SET_ORDER_PRIMARY_ATTACHMENT = 'SET_ORDER_PRIMARY_ATTACHMENT';
export const REMOVE_ORDER_ATTACHMENT = 'REMOVE_ORDER_ATTACHMENT';
export const SET_ORDER_CUSTOMER_ID = 'SET_ORDER_CUSTOMER_ID';
export const SET_ORDER_SALE_VENDOR = 'SET_ORDER_SALE_VENDOR';
export const ADD_STATUS_UPDATE_TO_ORDER = 'ADD_STATUS_UPDATE_TO_ORDER';
export const EDIT_STATUS_UPDATE_ON_ORDER = 'EDIT_STATUS_UPDATE_ON_ORDER';
export const REMOVE_STATUS_UPDATE_ON_ORDER = 'REMOVE_STATUS_UPDATE_ON_ORDER';
export const EDIT_ORDER_META = 'EDIT_ORDER_META';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const SET_ORDER_CHANGES = 'SET_ORDER_CHANGES';
export const GET_RETURNABLE_ITEMS = 'GET_RETURNABLE_ITEMS';
export const GET_FULFILLMENT_STATUSES = 'GET_FULFILLMENT_STATUSES';
export const OPEN_RETURN_ORDER = 'OPEN_RETURN_ORDER';
export const CONVERT_CURRENCY = 'CONVERT_CURRENCY';
export const GET_JEWELRY_REPAIR_COSTS = 'GET_JEWELRY_REPAIR_COSTS';
export const NEEDS_WARRANTY_ACTIVATION = 'NEEDS_WARRANTY_ACTIVATION';
export const GET_WARRANTY_ITEMS = 'GET_WARRANTY_ITEMS';

// Reports
export const GET_REPORT = 'GET_REPORT';
export const SET_REPORT_PROPERTY = 'SET_REPORT_PROPERTY';
export const SET_REPORT_PROPERTIES = 'SET_REPORT_PROPERTIES';
export const RESET_REPORT_PROPERTY = 'RESET_REPORT_PROPERTY';
export const RESET_REPORT = 'RESET_REPORT';
export const GET_MONEY_HELD = 'GET_MONEY_HELD';
export const GET_DAILY_PAYMENTS_REPORT = 'GET_DAILY_PAYMENTS_REPORT';
export const GET_SALES_TAX_THRESHOLD_REPORT = 'GET_SALES_TAX_THRESHOLD_REPORT';
export const SET_SALES_COMPARISON_COLUMNS = 'SET_SALES_COMPARISON_COLUMNS';
export const SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS = 'SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS';
export const GET_REPORT_LAYOUTS = 'GET_REPORT_LAYOUTS';
export const SET_REPORT_LAYOUT = 'SET_REPORT_LAYOUT';
export const REMOVE_REPORT_LAYOUT = 'REMOVE_REPORT_LAYOUT';
export const SET_REPORTS_ATTRIBUTE_FILTERS = 'SET_REPORTS_ATTRIBUTE_FILTERS';
export const SET_LAYOUTS_MODAL_VISIBLE = 'SET_LAYOUTS_MODAL_VISIBLE';
export const SET_NEW_LAYOUT_MODAL_VISIBLE = 'SET_NEW_LAYOUT_MODAL_VISIBLE';
export const SET_REPORTS_DRAWER_VISIBLE = 'SET_REPORTS_DRAWER_VISIBLE';
export const GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT = 'GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT';
export const GET_ACCOUNTS_RECEIVABLE_REPORT = 'GET_ACCOUNTS_RECEIVABLE_REPORT';
export const GET_DAILY_ON_ACCOUNT_REPORT = 'GET_DAILY_ON_ACCOUNT_REPORT';

// Physical Inventory
export const GET_INVENTORY_SNAPSHOTS = 'GET_INVENTORY_SNAPSHOTS';
export const ADD_SNAPSHOT = 'ADD_SNAPSHOT';
export const RESET_SNAPSHOT = 'RESET_SNAPSHOT';
export const GET_INVENTORY_SNAPSHOT = 'GET_INVENTORY_SNAPSHOT';
export const SET_INVENTORY_SNAPSHOT_FILTER = 'SET_INVENTORY_SNAPSHOT_FILTER';
export const UPDATE_INVENTORY_SNAPSHOT = 'UPDATE_INVENTORY_SNAPSHOT';

// Gift Cards
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD';
export const SET_GIFT_CARD = 'SET_GIFT_CARD';

// Ecom Products
export const GET_ECOM_PRODUCTS = 'GET_ECOM_PRODUCTS';
export const EDIT_ECOM_PRODUCT = 'EDIT_ECOM_PRODUCT';
export const GET_ECOM_IMAGES = 'GET_ECOM_IMAGES';

// Ecom Product Types
export const EDIT_ECOM_PRODUCT_TYPES = 'EDIT_ECOM_PRODUCT_TYPES';

// Ecom Product Statuses
export const GET_ECOM_VENDOR_PRODUCT_STATUSES = 'GET_ECOM_VENDOR_PRODUCT_STATUSES';
export const UPDATE_ECOM_VENDOR_PRODUCT_STATUSES = 'UPDATE_ECOM_VENDOR_PRODUCT_STATUSES';

// CASES
export const GET_CASES = 'GET_CASES';
export const GET_CASE = 'GET_CASE';
export const EDIT_CASE = 'EDIT_CASE';
export const ADD_CASE = 'ADD_CASE';

// MEMOS
export const CREATE_MEMO = 'CREATE_MEMO';
export const GET_MEMOS = 'GET_MEMOS';
export const GET_MEMO = 'GET_MEMO';
export const UPDATE_MEMO = 'UPDATE_MEMO';
export const ADD_MEMO_ITEM = 'ADD_MEMO_ITEM';
export const EDIT_MEMO_ITEM = 'EDIT_MEMO_ITEM';

export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER';
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const GET_PURCHASE_HISTORY = 'GET_PURCHASE_HISTORY';
export const ADD_PURCHASE_ORDER_ITEM = 'ADD_PURCHASE_ORDER_ITEM';
export const EDIT_PURCHASE_ORDER_ITEM = 'EDIT_PURCHASE_ORDER_ITEM';
export const CONVERT_PURCHASE_ORDER = 'CONVERT_PURCHASE_ORDER';
export const ADD_PURCHASE_ORDER_ITEM_RECEIPT = 'ADD_PURCHASE_ORDER_ITEM_RECEIPT';

export const SEARCH_DIAMONDS = 'SEARCH_DIAMONDS';
export const SET_DIAMOND_SEARCH_FILTERS = 'SET_DIAMOND_SEARCH_FILTERS';

export const GET_ORDER_ATTACHMENTS = 'GET_ORDER_ATTACHMENTS';
export const DELETE_ORDER_ATTACHMENTS = 'DELETE_ORDER_ATTACHMENTS';
export const GET_APPRAISAL_ATTACHMENTS = 'GET_APPRAISAL_ATTACHMENTS';
export const DELETE_APPRAISAL_ATTACHMENTS = 'DELETE_APPRAISAL_ATTACHMENTS';
export const GET_CLIENT_ATTACHMENTS = 'GET_CLIENT_ATTACHMENTS';
export const DELETE_CLIENT_ATTACHMENTS = 'DELETE_CLIENT_ATTACHMENTS';

export const SET_FILTERED_ITEMS = 'SET_FILTERED_ITEMS';

// CREDIT CARD PROCESSING
export const GET_TERMINALS = 'GET_TERMINALS';
export const GET_CARD_TRANSACTIONS = 'GET_CARD_TRANSACTIONS';
export const AUTH_CARD = 'AUTH_CARD';
export const AUTH_FALLBACK = 'AUTH_FALLBACK';
export const SET_CARD_PROCESSING = 'SET_CARD_PROCESSING';
export const RESET_CARD = 'RESET_CARD';
export const GET_CC_TRANSACTION = 'GET_CC_TRANSACTION';
export const PRINT_CARD_RECEIPT = 'PRINT_CARD_RECEIPT';
export const REFUND_CC_TRANSACTION = 'REFUND_CC_TRANSACTION';
export const INITIATED_TRANSACTION_SESSION = 'INITIATED_TRANSACTION_SESSION';
export const COMPLETED_TRANSACTION_SESSION = 'COMPLETED_TRANSACTION_SESSION';
export const RESET_TRANSACTION_SESSION = 'RESET_TRANSACTION_SESSION';

// Dashboard
export const GET_DASHBOARD_SALES = 'GET_DASHBOARD_SALES';
export const GET_DASHBOARD_METRICS = 'GET_DASHBOARD_METRICS';
export const GET_DASHBOARD_ORDERS = 'GET_DASHBOARD_ORDERS';
export const GET_DASHBOARD_REPAIRS = 'GET_DASHBOARD_REPAIRS';
export const GET_DASHBOARD_INVENTORIES = 'GET_DASHBOARD_INVENTORIES';
export const GET_DASHBOARD_INVENTORIES_RESULTS = 'GET_DASHBOARD_INVENTORIES_RESULTS';
export const GET_DASHBOARD_MERCH_ITEMS = 'GET_DASHBOARD_MERCH_ITEMS';
export const GET_DASHBOARD_INVENTORY_STATUS = 'GET_DASHBOARD_INVENTORY_STATUS';

// Integrations
export const GET_TAX_RATES_FOR_ZIP = 'GET_TAX_RATES_FOR_ZIP';
export const GET_ACTIVE_TAXJAR_ACCOUNT = 'GET_ACTIVE_TAXJAR_ACCOUNT';
export const ADD_ORG_TAXJAR_ACCOUNT = 'ADD_ORG_TAXJAR_ACCOUNT';
export const REMOVE_ORG_TAXJAR_ACCOUNT = 'REMOVE_ORG_TAXJAR_ACCOUNT';
export const GET_ZILLION_AUTO_QUOTE = 'GET_ZILLION_AUTO_QUOTE';
export const ZILLION_OFFER_RECEIVED = 'ZILLION_OFFER_RECEIVED';
export const GET_JM_STORE_CODES = 'GET_JM_STORE_CODES';
export const GET_JM_PRODUCT_MAP = 'GET_JM_PRODUCT_MAP';
export const UPDATE_JM_PRODUCT_MAP = 'UPDATE_JM_PRODUCT_MAP';
export const TOGGLE_JM_INTEGRATION = 'TOGGLE_JM_INTEGRATION';
export const STORE_JM_PLAN_SKU = 'STORE_JM_PLAN_SKU';
export const CLEAR_JM_PLAN_SKUS = 'CLEAR_JM_PLAN_SKUS';
export const REMOVE_JM_PLAN_SKU = 'REMOVE_JM_PLAN_SKU';

// Foreign exchange rate
export const SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA = 'SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA';

// Trade-Ins
export const SET_TRADE_IN_SEARCH = 'SET_TRADE_IN_SEARCH';
export const ADD_TRADE_IN_TO_ORDER = 'ADD_TRADE_IN_TO_ORDER';
export const REMOVE_TRADE_IN_FROM_ORDER = 'REMOVE_TRADE_IN_FROM_ORDER';
export const RESET_ORDER_TRADE_INS = 'RESET_ORDER_TRADE_INS';

// Brands
export const SET_BRANDS_SEARCH = 'SET_BRANDS_SEARCH';

// Collection
export const SET_COLLECTIONS_SEARCH = 'SET_COLLECTIONS_SEARCH';
